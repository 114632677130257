import React, { useState } from 'react';
import './Tools.css'; // Import CSS cho trang Tools

function Tools() {
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState('');

    // Hàm để kiểm tra và chỉnh sửa định dạng chuỗi
    const fixEmailFormat = (input) => {
        const regex = /\[([^\]]+)\]\(mailto:[^\)]+\):([^ ]+)/g;
        return input.replace(regex, '$1:$2'); // Thay thế thành email:password
    };

    const handleProcess = () => {
        setOutputText(fixEmailFormat(inputText));
    };

    return (
        <div className="tools-container">
            <h1>Công cụ: Lọc Mail Notion</h1>
            <textarea
                className="textarea-input"
                placeholder="Nhập văn bản ở đây..."
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
            />
            <button className="process-button" onClick={handleProcess}>
                Xử lý
            </button>
            <textarea
                className="textarea-output"
                placeholder="Kết quả sẽ hiện ở đây..."
                value={outputText}
                readOnly
            />
        </div>
    );
}

export default Tools;
