import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Home = () => {
    const [deroInfo, setDeroInfo] = useState(null);
    const [error, setError] = useState('');

    const fetchDeroInfo = async () => {
        try {
            const response = await axios.get('https://stargate.nelbert442.com:9394/api/getinfo');
            setDeroInfo(response.data.getinfo); // Lưu thông tin DERO vào state
        } catch (error) {
            console.error('Error fetching DERO info:', error);
            setError('Không thể lấy thông tin DERO.');
        }
    };

    useEffect(() => {
        // Gọi API ngay khi component được mount
        fetchDeroInfo();

        // Gọi lại API mỗi 10 giây
        const intervalId = setInterval(() => {
            fetchDeroInfo();
        }, 10000);

        // Dọn dẹp interval khi component bị unmount
        return () => clearInterval(intervalId);
    }, []);

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div>
            <h1>Thông tin DERO</h1>
            {deroInfo ? (
                <ul>
                    <li>Độ khó: {deroInfo.difficulty}</li>
                    <li>Chiều cao blockchain: {deroInfo.height}</li>
                    <li>Tổng cung: {deroInfo.total_supply}</li>
                    <li>Thời gian trung bình khối: {deroInfo.averageblocktime50}s</li>
                    <li>Mã hash khối đỉnh: {deroInfo.top_block_hash}</li>
                    {/* Thêm các thông tin khác nếu cần */}
                </ul>
            ) : (
                <p>Đang tải...</p>
            )}
        </div>
    );
};

export default Home;
