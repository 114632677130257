import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';
import News from './pages/News';
import TicTacToe from './pages/TicTacToe';
import Tools from './pages/Tools';
import Contact from './pages/Contact';
import './App.css';




function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Router>
      <div>
        <nav>
          <div className="hamburger" onClick={toggleMenu}>
            &#9776; {/* Ký hiệu hamburger */}
          </div>
          <ul className={isOpen ? "nav-links open" : "nav-links"}>
            <li><Link to="/" onClick={toggleMenu}>Trang chủ</Link></li>
            <li><Link to="/news" onClick={toggleMenu}>Coins</Link></li>
            <li><Link to="/games" onClick={toggleMenu}>Games</Link></li>
            <li><Link to="/tools" onClick={toggleMenu}>Công cụ</Link></li>
            <li><Link to="/contact" onClick={toggleMenu}>Liên hệ</Link></li>
          </ul>
        </nav>

        <div className="container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/news" element={<News />} />
            <Route path="/games" element={<TicTacToe />} />
            <Route path="/tools" element={<Tools />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
