import React, { useEffect, useState } from 'react';
import '../App.css';

function Contact() {
    const [contact, setContact] = useState({
        phone: '09.888.08.999',
        telegram: 't.me/hotrantien01'
    });

    return (
        <div className="container">
            <h1>Liên hệ</h1>
            <p>Telegram: {contact.telegram}</p>
            <p>Phone: {contact.phone}</p>
        </div>
    );
}

export default Contact;
