import React, { useState } from 'react';
import './TicTacToe.css';  // Để thêm kiểu dáng cho trò chơi


function TicTacToe() {
    const [board, setBoard] = useState(Array(9).fill(null));
    const [isXNext, setIsXNext] = useState(true);

    // Hàm để xử lý khi nhấn nút Reset
    const handleReset = () => {
        setBoard(Array(9).fill(null));
        setIsXNext(true);
    };

    const handleClick = (i) => {
        const newBoard = board.slice();
        if (calculateWinner(board) || board[i]) return;
        newBoard[i] = isXNext ? 'X' : 'O';
        setBoard(newBoard);
        setIsXNext(!isXNext);
    };

    const renderSquare = (i) => (
        <button className="square" onClick={() => handleClick(i)}>
            {board[i]}
        </button>
    );

    const winner = calculateWinner(board);
    let status;
    if (winner) {
        status = `Winner: ${winner}`;
    } else if (board.every(square => square)) {
        status = 'Draw!';
    } else {
        status = `Next player: ${isXNext ? 'X' : 'O'}`;
    }

    return (
        <div>
            <h1>Tic Tac Toe</h1>
            <div className="status">{status}</div>
            <div className="board">
                <div className="board-row">
                    {renderSquare(0)}
                    {renderSquare(1)}
                    {renderSquare(2)}
                </div>
                <div className="board-row">
                    {renderSquare(3)}
                    {renderSquare(4)}
                    {renderSquare(5)}
                </div>
                <div className="board-row">
                    {renderSquare(6)}
                    {renderSquare(7)}
                    {renderSquare(8)}
                </div>
            </div>
            {/* Nút Reset */}
            <button className="reset-button" onClick={handleReset}>Reset Game</button>
        </div>
    );
}

function calculateWinner(squares) {
    const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
            return squares[a];
        }
    }
    return null;
}

export default TicTacToe;
