import React, { useEffect, useState } from 'react';
// import './News.css';
import '../App.css';  // Import CSS
import axios from 'axios';

const News = () => {
    const [coinData, setCoinData] = useState([]);
    const [deroBalance, setDeroBalance] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        // Gọi API của server Express để lấy dữ liệu coin
        const fetchCoinData = async () => {
            try {
                const response = await axios.get('http://localhost:3001/api/coins');
                setCoinData(response.data);
            } catch (error) {
                console.error('Error fetching coin data:', error);
            }
        };

        // Lấy số dư DERO từ API
        const fetchDeroBalance = async () => {
            try {
                const response = await axios.post('http://localhost:3001/api/account-balance', {
                    currency: 'DERO' // Gửi tham số currency dưới dạng JSON
                });
                setDeroBalance(response.data);
            } catch (error) {
                console.error('Error fetching balance data:', error);
                setError('Lỗi khi lấy dữ liệu số dư.');
            }
        };

        fetchCoinData();
        fetchDeroBalance();
    }, []);

    // Hàm để làm tròn số và giữ lại 4 chữ số sau dấu thập phân
    const formatNumber = (num) => {
        return parseFloat(num).toFixed(4);
    };

    return (
        <div className="container">
            <h1>Tin tức coin</h1>
            {coinData.length === 0 ? (
                <p>Đang tải dữ liệu...</p>
            ) : (
                <div className="coin-grid">
                    {coinData.map((coin, index) => (
                        <div className="coin-item" key={index}>
                            <h3>{coin.symbol}</h3> {/* Hiển thị tên loại coin */}
                            <p><strong>Price:</strong> {formatNumber(coin.price)}</p>
                            <p><strong>High (24h):</strong> {formatNumber(coin.high)}</p>
                            <p><strong>Low (24h):</strong> {formatNumber(coin.low)}</p>
                            <p><strong>Volume:</strong> {formatNumber(coin.volume)}</p>
                        </div>
                    ))}
                </div>
            )}
            <div className="balance-info">
                <h2>Số dư DERO</h2>
                {error && <p>{error}</p>}
                {deroBalance ? (
                    <div>
                        <p><strong>Balance:</strong> {formatNumber(deroBalance.balance)}</p>
                        <p><strong>Available:</strong> {formatNumber(deroBalance.available)}</p>
                    </div>
                ) : (
                    <p>Đang tải số dư...</p>
                )}
            </div>
        </div>
    );
};

export default News;
